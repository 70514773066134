@use '@nex/labs/src/styles/common.scss' as *;

.ProPlans {
  padding: 0 14px;
}

.Plans {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 32px;
  margin-bottom: 24px;
  justify-content: center;

  ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 12px;
    list-style: disc;
    list-style-position: inside;

    li {
      @include typography('p');
    }
  }

  .Plan {
    background: #f7f7f7;
    border-radius: 8px;
    height: 100%;
    padding: 22px;
    display: flex;
    flex-direction: column;
    gap: 22px;
    flex: 0 0 23%;
    height: auto;

    @include media('md', 'max') {
      flex: 0 0 100%;
    }
  }
}

.ProPlanModalImage {
  max-width: 500px;
  margin: -50px -18px -24px 0;
  object-fit: cover;
}
