@use '@nex/labs/src/styles/common.scss' as *;

.Discover {
  background: var(--deep-black);
}

.DiscoverHeader {
  background: url('/images/home/spiral.svg');
  background-attachment: fixed;
  background-size: cover;
  margin-top: -84px;
  background-color: #081414;
  padding: 7.5rem 0 5rem;
  margin-top: -76px;
  position: relative;
  overflow: hidden;

  .HeroTagline {
    color: #64cf81;
    border-radius: 25px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.05);
    background: bg('white', 'soft');
    backdrop-filter: blur(8px);
    padding: 6px 18px;
    width: fit-content;
    margin: auto 0px 13px;
    font-size: 14px;
    font-weight: 500;
  }

  h1 {
    line-height: 70px;
    max-width: 680px;
    font-weight: 600;
    margin: auto 0px 1.5rem;
    font-size: 60px;

    @include media('lg', 'max') {
      font-size: 40px;
      line-height: 50px;
    }
  }

  h5 {
    max-width: 600px;
    line-height: 1.7;
    margin: auto 0px 2rem;
  }

  .DiscoverHeader__Actions {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin: auto 0px;
    max-width: 650px;

    div[data-amlabs-field-wrapper] {
      background: #ffffff0a;
      backdrop-filter: blur(4px);

      input {
        padding: 22px 18px;
      }
    }
  }
}

.DiscoverSection {
  padding-bottom: 6rem;
}

.Generations {
  // column-count: 4;
  // column-gap: 18px;
  // row-gap: 18px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-template-rows: minmax(260px, auto);
  gap: 15px;
  grid-gap: 15px;

  @include media('lg', 'max') {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}

.Generation {
  border: 1px solid var(--border-gray);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 4px;

  a {
    height: 100%;
  }

  &.list {
    box-shadow: none;
    border: none;
    border-bottom: 1px solid var(--border-gray);
    border-radius: 0px;
    padding: 8px 4px;
    margin-bottom: 0px;

    img,
    video {
      &:not(.GenerationActions) {
        width: 40px !important;
        height: 40px !important;
        object-fit: cover;
      }
    }
  }

  img,
  video {
    width: 100%;
    height: 100%;
    max-height: 210px;
    display: block;
    -o-object-fit: cover;
    object-fit: contain;
    border-radius: 8px;
    position: relative;
    z-index: 1;
  }

  .MetaImage {
    height: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 8px;

    &:before {
      filter: blur(10px);
      position: absolute;

      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 120%;
      height: 120%;
      -o-object-fit: fill;
      object-fit: fill;

      content: '';
      background-image: var(--img-bg);
    }

    &:hover {
      .Generations__Meta {
        opacity: 1;
        transition: all 0.2s ease-in-out;
      }
    }

    .Generations__Meta {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      padding: 12px;
      background: linear-gradient(rgba(0, 0, 0, 0.1) 10%, rgb(0 0 0 / 80%) 80%);
      backdrop-filter: blur(1px);
      border-radius: 0 0 8px 8px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: 12px;
      opacity: 0;
      z-index: 2;

      p {
        margin: 0;
        text-align: left;

        color: white;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
}

.Generation {
  .Generations__Context {
    opacity: 0;
  }
  &:hover {
    .Generations__Context {
      opacity: 1;
      transition: all 0.2s ease-in-out;
    }
  }
}

.virtual-scroll-container {
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }
}
