@use '@nex/labs/src/styles/common.scss' as *;

.Enhance {
  margin-left: var(--aside-width);
  width: var(--main-width);
  position: relative;
  background-color: var(--primary-gray);
  // background-image: radial-gradient(#d9d9d9 1.5px, transparent 1.5px);
  background-size: calc(14 * 1.5px) calc(14 * 1.5px);
  display: flex;
  min-height: var(--nex-labs-vh);
  flex-direction: column;
  --aside-width: 250px;

  .EnhanceWrapper {
    display: flex;
    flex-wrap: wrap;

    align-items: flex-start;
    border-radius: 12px;

    @include media('lg', 'max') {
      flex-direction: row-reverse;
    }

    aside {
      width: var(--aside-width);
      height: var(--nex-labs-vh);
      background: var(--primary-white);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      padding: 8px;
      box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.05);
      padding: 16px;
      gap: 8px;
      z-index: 100;

      // background: rgb(30, 30, 30);
      // border-left: 1px solid bg('white', '5');

      .asideInert {
        display: flex;
        flex-direction: column;
        gap: 18px;
        max-height: 95%;
        overflow-x: scroll;
      }

      fieldset {
        > div {
          select,
          textarea {
            padding: 14px;
          }
        }
      }
    }

    @include media('lg', 'max') {
      flex-direction: column-reverse;

      aside {
        flex: 0 100%;
        max-width: 100%;
        width: 100%;
        margin-top: 10px;
        padding: 80px 16px 16px;
      }

      .ImageSection {
        min-height: 100% !important;
      }
    }

    section {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 14px;
      padding: 0;
      max-height: 100vh;
      overflow: hidden;
      margin-left: 12px;
      padding: 0 14px;
      justify-content: center;

      @include media('lg', 'max') {
        width: 100%;
        margin-left: 0px !important;
      }
    }

    .enhanceFooter {
      border: 1px solid var(--border-light);
      background: rgb(255, 255, 255);
      padding: 4px 12px;
      border-radius: 12px;
      margin: 4.5rem -4px 0;
      position: sticky;
      top: 10px;
      z-index: 100;
    }

    .ImageSection {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      border-radius: 12px;
      max-height: calc(100vh - 100px);

      input[type='range'] {
        -webkit-appearance: none;
        -moz-appearance: none;
        position: absolute;
        top: 0;
        left: -0%;
        background-color: transparent;
        width: 100%;
        height: 100%;
        z-index: 2;
      }

      input[type='range']:focus {
        outline: none;
      }

      input[type='range']:hover {
        cursor: grab;
      }

      input[type='range']:active {
        outline: none;
        cursor: grabbing;
      }

      input[type='range']::-moz-range-track {
        -moz-appearance: none;
        background-color: transparent;
        position: relative;
        outline: none;
      }

      input[type='range']::active {
        border: none;
        outline: none;
      }

      input[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 30px;
        height: 30px;
      }

      input[type='range']::-moz-range-thumb {
        -moz-appearance: none;
        width: 30px;
        height: 30px;
      }

      input[type='range']:focus::-webkit-slider-thumb {
        background-color: transparent;
      }

      input[type='range']:focus::-moz-range-thumb {
        background-color: transparent;
      }

      .Divisor {
        position: absolute;
        width: 45px;
        border: #bdbdbdba 1px solid;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        right: -21px;
        background-color: #ffffffba;
        height: 45px;
        z-index: 1;
        background-size: 22px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.0003 6.8999C11.5503 6.8999 11.1753 7.2749 11.1753 7.7249V16.2374C11.1753 16.6874 11.5503 17.0624 12.0003 17.0624C12.4503 17.0624 12.8253 16.6874 12.8253 16.2374V7.7249C12.8253 7.2749 12.4503 6.8999 12.0003 6.8999Z' fill='%23000'/%3E%3Cpath d='M8.7002 11.175H3.6002L6.4877 8.36255C6.8252 8.02505 6.8252 7.50005 6.4877 7.16255C6.1502 6.82505 5.6252 6.82505 5.2877 7.16255L0.900195 11.4C0.750195 11.55 0.637695 11.775 0.637695 12C0.637695 12.225 0.712695 12.45 0.900195 12.6L5.3252 16.875C5.4752 17.025 5.7002 17.1 5.9252 17.1C6.1502 17.1 6.3752 17.025 6.5252 16.8375C6.8627 16.5 6.8252 15.975 6.4877 15.6375L3.5627 12.825H8.7002C9.1502 12.825 9.5252 12.45 9.5252 12C9.5252 11.55 9.1502 11.175 8.7002 11.175Z' fill='%23000'/%3E%3Cpath d='M23.1001 11.4L18.6751 7.12495C18.3376 6.78745 17.8126 6.78745 17.4751 7.12495C17.1376 7.46245 17.1376 7.98745 17.4751 8.32495L20.3626 11.1375H15.3001C14.8501 11.1375 14.4751 11.5125 14.4751 11.9625C14.4751 12.4125 14.8501 12.7875 15.3001 12.7875H20.4376L17.5126 15.5999C17.1751 15.9374 17.1751 16.4625 17.4751 16.7999C17.6251 16.9874 17.8501 17.0625 18.0751 17.0625C18.3001 17.0625 18.4876 16.9875 18.6751 16.8375L23.0626 12.6C23.2126 12.45 23.3251 12.225 23.3251 12C23.3251 11.775 23.2501 11.5875 23.1001 11.4Z' fill='%23000'/%3E%3C/svg%3E%0A");
        border-radius: 50%;
        backdrop-filter: blur(6px) saturate(4.5);
        &:before {
          position: absolute;
          width: 1px;
          height: 100vw;
          background: var(--text-gray-light);
          left: 50%;
          top: 50%;
          z-index: -1;
          content: '';
          transform: translate(-50%, -50%);
        }
      }

      figure {
        background-size: cover;
        position: relative;
        font-size: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        margin: 0;
        user-select: none;

        .ImgBase {
          height: 100%;
          width: 100%;
        }

        .ImageDivisor {
          position: absolute;
          height: 100%;
          border-radius: 12px;
          bottom: 0;
          left: 0;
          clip-path: inset(0 50% 0 0);

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }

    .OtherImages {
      max-width: 500px;
      overflow: auto;
      background: var(--primary-gray);
      padding: 4px;
      border-radius: 6px;
      display: flex;
      gap: 8px;
      margin-left: -48px;
      border: 1px solid var(--border-light);

      @include media('md', 'max') {
        margin-left: 0;
      }

      button {
        min-width: 40px;
      }

      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 6px;
      }
    }

    .ActionBar {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;

      button {
        background: #f5f5f5;
        border: 1px solid #e5e5e5;
        padding: 6px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        border-radius: 6px;

        &.active {
          svg {
            color: var(--primary-theme);
          }
        }

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}

.ImageUpload {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  padding: 14px;
  text-align: center;
  border: bg('black', '6') 1px solid;
  border-radius: 12px;
}

.AssetUpload {
  display: flex;
  flex-direction: column;
  justify-content: center;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  background: #f9fafb;
  border: 1px solid var(--border-light);
  border-radius: 8px;
  padding: 14px 0px 0px;
  overflow: hidden;

  button {
    border-radius: 0px 0px 0px 0px !important;
    background: var(--primary-white) !important;
    margin-top: 10px;
    border: none !important;
    border-top: 1px solid var(--border-light) !important;
  }
}

.upscaleTabs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  background-color: var(--primary-gray);
  border: 1px solid rgba(16, 16, 16, 0.03);
  border-radius: 50px;
  gap: 8px;
  padding: 2px;

  button {
    font-size: 14px;
    padding: 6px 0;
    flex: 1;
    border-radius: 50px;
    font-weight: 600;
    opacity: 0.6;

    &.active {
      opacity: 1;
      background: var(--primary-white);
    }
  }
}
