@use '@nex/labs/src/styles/common.scss' as *;

@property --gradient-angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

.ConsoleWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.ConsoleHero {
  padding: 12px 0;
  position: relative;
  display: grid;
  place-items: center;
}

.ConsoleMeta {
  --gap: 24px;

  display: flex;
  align-items: center;
  gap: var(--gap);
  flex-direction: row;

  &Credit {
    display: flex;
    align-items: center;
    gap: var(--gap);

    &CTA {
      font-size: 8px !important;
    }
  }
}

.tag {
  font-size: 10px;
  font-weight: 800;
  @include applySmartBg('white', 'faint', 'regular');
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid var(--primary-theme);
  padding: 0px 9px;
  border-radius: 50px;
  color: var(--primary-theme);
  margin-left: 5px;
  display: inline-block;
  position: relative;
  line-height: 2;
  top: -4px;
}

.ConsoleActionSlateButtons {
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  grid-template-rows: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 14px;

  @include media('lg', 'max') {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .ConsoleActionSlateButton {
    display: flex;
    border-radius: 8px;
    padding: 0px;
    overflow: hidden;
    gap: 28px;
    align-items: flex-start;
    background: #050b1e;
    justify-content: flex-start;
    padding: 24px;
    flex: 1;
    color: var(--primary-white);
    position: relative;

    &:nth-child(3) {
      opacity: 0.5;
      pointer-events: none;
    }

    &.LightVersion {
      box-shadow: 0px 2px 4px 0px #0000000d;
      background: var(--primary-gray);
      color: var(--text-black);
      border: 1px solid var(--primary-gray);
    }

    &:hover,
    &:focus-visible {
      transition: all 0.2s ease-in-out;

      opacity: 0.9;
      h5 {
        color: var(--primary-theme);
      }
    }

    @include media('lg', 'max') {
      min-width: 100%;
    }

    img {
      height: 100%;
      max-width: 90px;
      border-radius: 0;
      margin-left: -32px;
      object-fit: contain;

      @include media('md', 'max') {
        display: none;
      }
    }
  }
}

.ConsolePresetSlatesButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 22px;
}

.ConsoleMessage {
  background: transparent;
  border-radius: 50px;
  padding: 10px 24px;
  margin: 0 auto;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: fit-content;
  position: relative;
  z-index: 2;

  strong {
    font-weight: 600;
    color: var(--primary-theme);
  }
}

.ActiveActions {
  max-width: 90%;
  margin: initial;
  position: relative;
  z-index: 2;
  justify-content: flex-start !important;

  > a {
    white-space: nowrap;
    font-size: var(--font-p);
    background: #fff !important;
    font-weight: 500;
    padding: 6px 14px !important;
  }
}

.ConsoleBlurBleed {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 0;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 420px;
  opacity: 0.35;
  z-index: 1;
}

.ConsoleRecentWorks {
  .ConsoleRecentWorksGrid {
    margin-top: 22px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-template-rows: 1fr;
    grid-column-gap: 14px;
    grid-row-gap: 14px;

    @include media('lg', 'max') {
      grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    }

    &Item {
      display: flex;
      // border: 1px solid bg('white', 'faint');
      background: bg('black', 'faint');
      border-radius: 12px;
      padding: 12px 10px;
      flex-direction: column;
      gap: 15px;

      img {
        width: 100%;
        height: 180px;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }
}

.SetupChecklist {
  border: 1px solid #e7e9eb;
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  background: var(--primary-white);
  justify-content: space-between;

  .ChecklistItem {
    padding: 14px;
    border: 1px solid #f3f4f4;
    border-radius: 8px;

    &.active {
      .ChecklistItemStatusIndex {
        transition: all 0.3s ease;
        background: var(--primary-theme);
        color: var(--text-black);
      }
      background: #f7f7f7;
      border: 1px solid var(--primary-theme);
    }

    &.done {
      p {
        color: #868e96;
      }
    }

    .ChecklistItemStatus {
      svg {
        width: 30px;
        height: 30px;
      }
    }

    .ChecklistItemStatusIndex {
      background: var(--primary-gray);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .Checklist {
    padding: 24px;
    width: 100%;
    max-width: 45%;

    @include media('xl', 'max') {
      max-width: 100%;
      padding: 16px;
    }
  }

  .ChecklistItemDetails {
    padding: 32px;
    display: flex;
    align-items: center;
    border-left: 1px solid #e7e9eb;
    background: #f5f5f5;
    gap: 32px;
    justify-content: center;
    flex: 1;

    @include media('xl', 'max') {
      flex: 1 1 100%;
      flex-wrap: wrap;
    }

    > img {
      max-width: 270px;
    }
  }
}

.ConsoleBannerArea {
  background: linear-gradient(
    122.17deg,
    #050d21 -2.65%,
    #1e33a0 45.5%,
    #8f7b88 98.72%
  );
  padding: 24px 32px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  color: var(--primary-white);

  .ConsoleBannerArea__badge {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 6px 8px;
    background: rgb(255 255 255 / 14%);
    border-radius: 50px;
    width: fit-content;
    font-weight: 600;

    > span {
      background: #fff0d8;
      border-radius: 50px;
      padding: 4px 10px;
      font-size: 12px;
      color: var(--text-black);
    }
  }

  > img {
    max-width: 45%;
    border-radius: 8px;
  }
}

.ConsoleMarkoActionCard {
  background: url('/images/misc/app-card.png');
  background-color: rgb(0 0 0 / 46%);
  color: #fff;
  background-size: cover;
  background-position: center;
  border-radius: 12px;
  padding: 32px;
  background-blend-mode: overlay;
}

.ConsoleMarkoAction {
  background: linear-gradient(163.75deg, #f9fbff -45.5%, #f9fbff 98.6%);
  border: 1px solid var(--border-light);
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 14px;
  min-height: 360px;
  border-radius: 14px;

  .ConsoleMask {
    position: absolute;
    pointer-events: none;
    user-select: none;
    right: -30px;
    height: auto;
    width: auto;
    top: 0;
  }

  .MarkoButton {
    border-radius: 8px;
    width: fit-content;
    display: flex;
    margin-top: 18px;
    align-items: center;
    background: #fff;
    // overflow: hidden;
    padding: 10px 24px;
    z-index: 1;
    gap: 8px;
    font-size: var(--font-p);
    font-weight: 600;
    position: relative;

    --clr-1: #ffcee0;
    --clr-2: #f1f1ff;
    --clr-3: #d4e3ff;
    --clr-4: #6aa9f5;
    --clr-5: #ff96c8;

    @keyframes rotation {
      0% {
        --gradient-angle: 0deg;
      }
      100% {
        --gradient-angle: 360deg;
      }
    }

    &::after {
      content: '';
      position: absolute;
      background: #fff;
      height: 100%;
      width: 100%;
      left: 0;
      border-radius: 4px;
      z-index: -1;
    }

    &::before {
      content: '';
      position: absolute;
      inset: -3px;
      z-index: -1;
      background: conic-gradient(
        from var(--gradient-angle),
        var(--clr-3),
        var(--clr-4),
        var(--clr-5),
        var(--clr-4),
        var(--clr-3)
      );
      border-radius: inherit;
      animation: rotation 5s linear infinite;
    }

    &:focus-within {
      outline: 1px solid var(--primary-theme);
      outline-offset: 2px;
    }

    input {
      height: 100%;
      border: none;
      background: transparent;
      outline: none;
      padding: 18px 8px;
      width: 100%;
    }
  }
}
