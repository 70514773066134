@use '@nex/labs/src/styles/common.scss' as *;

.AssetsCard {
  flex: 0 1 calc(100% / 4.3);
  padding: 4px;
  background: white;
  height: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid var(--border-light);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  @include media('md', 'max') {
    flex: 0 1 calc(100% / 2);
  }

  img,
  video {
    border-radius: 4px;
    height: 150px;
    object-fit: cover;
  }
}

.GenerationImage {
  padding: 32px;
  max-width: fit-content;
  margin: auto;
  position: relative;
  margin-top: -32px;

  @include media('lg', 'max') {
    padding: 56px 32px 32px;
  }
}

.GenerationImageActions {
  position: absolute;
  right: 40px;
  bottom: 6%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  @include media('xl', 'max') {
    bottom: calc(6% + 17px);
  }

  button {
    background: #ffffffba !important;
    border-radius: 50px;
    padding: 4px 8px;
    backdrop-filter: blur(14px);
  }
}
