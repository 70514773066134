@use '@nex/labs/src/styles/common.scss' as *;

.SearchWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.SearchHero {
  padding: 48px 24px 0px;
  position: relative;
  margin: 0 -32px;
  margin-top: -48px;
  margin-bottom: 24px;
}

.SearchIntroSlateBG {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  height: calc(100% + 45px);
  width: 100%;
  position: absolute;
  top: -48px;
  left: 0;
}

.SearchIntroSlate {
  position: relative;
  overflow: hidden;
  padding: 0px 8px 48px;
  background-size: 100%;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  input {
    font-size: 16px;
    padding: 16px;
  }

  h4 {
    position: relative;
  }
}
