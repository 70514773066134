.FolderGrid {
  --slate-size: 265px;
  display: grid;
  gap: 14px;
  margin-top: 14px;
  grid-template-columns: repeat(auto-fill, minmax(var(--slate-size), 1fr));

  &.list {
    --slate-size: 100%;
    gap: 0;

    .FolderArea__card {
      border: none;
      border-bottom: 1px solid #e9ecf2;
      box-shadow: none;
      padding: 8px;
      border-radius: 1px;
      > img,
      .alternateBG {
        display: none;
      }

      &__action {
        flex-direction: row;
        align-items: center;
        gap: 40px;
      }

      &__content {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
        width: 100%;

        p:last-child {
          margin-top: auto;
          text-align: left;
        }
      }
    }
  }

  .FolderArea__card {
    display: flex;
    flex-direction: row;
    gap: 12px;
    background: var(--primary-white);
    border: 1px solid #e9ecf2;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    max-width: 100%;
    overflow: hidden;
    height: 100%;
    padding: 4px;

    .Generations__Context {
      opacity: 0;
    }

    &:hover {
      .Generations__Context {
        opacity: 1;
        transition: opacity 0.3s;
      }
    }

    > img {
      width: 100%;
      max-width: 90px;
      max-height: 100px;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }

    &__action {
      margin-top: auto;
    }

    &__content {
      padding: 4px;
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 2px;

      p:last-child {
        margin-top: auto;
      }
    }
  }
}

.alternateBG {
  background: var(--border-gray);
  width: 100px;
  display: flex;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
}

.virtual-scroll-container {
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

  &:hover {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 3px;
    }
  }

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}
