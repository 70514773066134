@use '@nex/labs/src/styles/common.scss' as *;
@import '@/features/console/artboard/components/modals/generation-info/generation-info.module.scss';

.CollectionItem {
  display: block;
  width: 100%;
  text-align: left;
  background: bg('white', '2');
  border: 1px solid bg('white', 'faint');
  padding: 12px 8px;
  border-radius: 6px;
  height: 100%;
  width: 100%;

  img {
    object-fit: cover;
    border-radius: 6px;
  }

  &:hover {
    background: bg('white', 'soft');
    transition: all 0.2s ease-in-out;
  }

  &.active {
    border: 1px solid var(--primary-theme);
    transition: all 0.2s ease-in-out;

    h6 {
      color: var(--primary-theme);
    }
  }
}

.Mansory {
  &.list {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    grid-gap: 16px;
    grid-template-rows: 1fr;
  }
}

.Item {
  transition: all 0.2s ease-in-out;
  height: 100%;
  width: 100%;
  max-height: 300px;

  &:hover {
    opacity: 0.8;
  }

  img {
    height: 100%;
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
  }
}

.CollectionItem {
  display: block;
  width: 100%;
  text-align: left;
  background: bg('white', '2');
  border: 1px solid bg('white', 'faint');
  padding: 12px 8px;
  border-radius: 6px;

  img {
    object-fit: cover;
    border-radius: 6px;
    max-height: 80px;
  }

  &:hover {
    background: bg('white', 'soft');
    transition: all 0.2s ease-in-out;
  }

  &.active {
    border: 1px solid var(--primary-theme);
    transition: all 0.2s ease-in-out;

    h6 {
      color: var(--primary-theme);
    }
  }
}
