@use '@nex/labs/src/styles/common.scss' as *;

.StorageDrawer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 350px;
  background: #ffffff;
  border: 1px solid #e4e4e7;
  z-index: 999;
  box-shadow:
    0px 2px 4px -2px rgba(0, 0, 0, 0.1),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  padding: 24px;
  overflow-y: auto;
  @include custom-scrollbar();
}

.File {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;

  .FileImage {
    width: 45px;
    height: 45px;
    border: 4px solid var(--border-gray);
    border-radius: 8px;
    object-fit: cover;
  }
}

.DrawerBackdrop {
  position: fixed;
  inset: 0;
  width: 100%;
  height: var(--am-labs-vh);
  background: rgba(0, 0, 0, 0.89);

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    background: rgba(0, 0, 0, 0.69);
    -webkit-backdrop-filter: blur(12.5px);
    backdrop-filter: blur(12.5px);
  }
}

@keyframes progress {
  0% {
    width: 0%;
  }
  50% {
    width: 70%;
  }
  100% {
    width: 100%;
  }
}

.AnimateProgress {
  border-radius: 50px;
  height: 100%;
  background: var(--primary-theme);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    border-radius: 50px;
    background: #ffffff5c;
    animation: progress 2s infinite;
  }
}
