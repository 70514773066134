@use '@nex/labs/src/styles/common.scss' as *;

.AssetsCard {
  flex: 0 1 calc(100% / 4.3);
  padding: 4px;
  background: white;
  height: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid var(--border-light);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  @include media('md', 'max') {
    flex: 0 1 calc(100% / 2);
  }

  img,
  video {
    border-radius: 4px;
    height: 150px;
    object-fit: cover;
  }
}

.GenerationImageWrapper {
  background: var(--primary-gray);
  margin: -52px -24px -24px;
  padding: 28px;
  flex: 0 1 66.5%;
  align-items: center;
  position: relative;

  @include media('xl', 'max') {
    flex: 1 1 100%;
    margin: -52px -24px 16px;
    align-items: center;
    width: 100%;
  }
}

.GenerationImage {
  max-width: fit-content;

  > img {
    width: 100%;
  }
}

.GenerationImageActions {
  position: absolute;
  right: 40px;
  bottom: 40px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  &.video {
    bottom: unset;
    top: 40px;
  }

  @include media('xl', 'max') {
    bottom: calc(6% + 17px);
  }

  button {
    background: #ffffffba !important;
    border-radius: 50px;
    padding: 4px 8px;
    backdrop-filter: blur(14px);
    min-width: 30px;
    min-height: 30px;
  }
}
