@use '@nex/labs/src/styles/common.scss' as *;

.GeneralSettings {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 32px;
  height: 100%;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;

  .GeneralSettingsNavigation,
  .GeneralSettingsContent {
    display: flex;
    flex-direction: column;
  }

  .GeneralSettingsContent {
    // background: var(--primary-gray);
    border: 1px solid bg('black', '8');
    border-radius: 12px;
    height: 100%;
  }

  .GeneralSettingsNavigation {
    gap: 18px;

    @include media('md', 'max') {
      gap: 0;
    }

    .Link {
      padding: 14px;
      border-radius: 6px;
      text-align: left;
    }

    .active {
      transition: all 0.2s ease-in-out;
      background: #f5f5f5;
      // border: 1px solid bg('black', '1');
      h6 {
        // color: var(--primary-theme);
      }
    }

    .Icon {
      background: var(--primary-white);
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
    }
  }

  .GeneralSettingsContent {
    flex: 1;

    gap: 8px;
    padding: 32px;

    @include media('md', 'max') {
      padding: 24px;
    }

    .uploadImage {
      background: bg('black', 'faint');
      width: fit-content;
      padding: 15px;
      margin-bottom: 2rem;
      border-radius: 6px;
    }
  }
}

.PlansHeader {
  text-align: center;
  padding: 48px 18px 0;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  border-radius: 12px;
  background-position: top;
  background-size: cover;
}

.Plans {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;

  .Plan {
    background: #f7f7f7;
    border-radius: 8px;
    height: 100%;
    padding: 22px;
    display: flex;
    flex-direction: column;
    gap: 22px;
    flex: 1 1 335px;
    height: auto;
    max-width: 380px;

    @include media('md', 'max') {
      flex: 0 0 100%;
    }

    &:nth-of-type(1) {
      .PlanBadge {
        path {
          fill: #3fc1c9;
        }
      }
    }

    &:nth-of-type(2) {
      .PlanBadge {
        path {
          fill: #f6d365;
        }
      }
    }

    &:nth-of-type(3) {
      .PlanBadge {
        path {
          fill: #fda085;
        }
      }
    }

    &Header {
      min-height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include media('md', 'max') {
        min-height: 100px;
      }

      .PlanBadge {
        height: 40px;
        display: block;
        width: 40px;
        margin-bottom: 10px;
      }
    }

    ul {
      list-style: disc;
      list-style-position: inside;

      li {
        margin-bottom: 8px;
      }
    }
  }
}

.ReferralUpsell {
  background: linear-gradient(#00000094, #00000094),
    url('/images/misc/console/refer.jpeg');
  padding: 24px;
  width: 100%;
  border-radius: 8px;
  color: var(--primary-white);
  background-position: center -160px;
  background-size: cover;
  margin-top: 32px;

  @supports (background-blend-mode: overlay) {
    background-blend-mode: overlay;
    background-image: url('/images/misc/console/refer.jpeg');
    background-color: rgb(0 0 0 / 83%);
  }

  @include media('md', 'max') {
    background-position: center;
  }
}

.PlanCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  max-width: 500px;

  background: var(--primary-white);
  border: 1px solid var(--border-light);
  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;

  .PlanCardAction {
    margin-top: auto;
    padding: 8px;
    border-top: 1px solid var(--border-light);
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  }
}

.SeatsCalc {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;
  background: var(--primary-white);
  border: 1px solid var(--border-light);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 18px;
}
