@use '@nex/labs/src/styles/common.scss' as *;
// @import '../console-home/console-home.module.scss';

.Results {
  display: flex;
  padding: 0 24px 24px;
  flex-direction: column;

  &::before {
    content: ' ';
    background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 1px solid #3a3a3a;
    left: -6.5px;
    width: 14px;
    height: 14px;
    margin-top: 2px;
    z-index: 1;
  }
}

.Timeline {
  position: relative;
  &::before {
    content: ' ';
    background: linear-gradient(
      -180deg,
      bg('white', '30') 70%,
      transparent 100%
    );
    display: inline-block;
    position: absolute;
    left: 0px;
    width: 1px;
    top: 4px;
    height: 100%;
    z-index: 1;
  }
}

@mixin cards {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: var(--primary-white);
  border: 1px solid #e9ecf2;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  max-width: 100%;
  overflow: hidden;
  padding: 4px;

  > img {
    width: 100%;
    border-radius: 4px;
    max-height: 180px;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    padding: 2px 8px 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

.AssetsGrid {
  --slate-size: 235px;
  display: grid;
  gap: 14px;
  margin-top: 14px;
  grid-template-columns: repeat(auto-fill, minmax(var(--slate-size), 1fr));

  .ConsoleArtboardsArea__card {
    display: flex;
    flex-direction: row;
    gap: 12px;
    background: var(--primary-white);
    border: 1px solid #e9ecf2;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    max-width: 100%;
    overflow: hidden;
    padding: 4px;

    > img {
      width: 100%;
      max-width: 90px;
      max-height: 100px;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }

    &__content {
      padding: 8px 0;
      display: flex;
      flex-direction: column;
      gap: 2px;

      p:last-child {
        margin-top: auto;
      }
    }
  }
}

.ArtboardGrid {
  --slate-size: 235px;
  display: grid;
  gap: 14px;
  margin-top: 14px;
  grid-template-columns: repeat(auto-fill, minmax(var(--slate-size), 1fr));

  .ConsoleArtboardsArea__card {
    @include cards;

    &--add {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 1px solid #0000000d;
      border-radius: 8px;
      padding: 24px;
      gap: 14px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      background: #fbfbfc;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        background: #d7d9e2;
      }

      &:hover {
        border: 1px dashed #b0b0b0;
        color: #000;
      }
    }
  }
}

.virtualizedContainer {
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}
